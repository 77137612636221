.wrapper {
  background: inherit;
  border-radius: 15px;
  /* border: 1px solid rgba(255, 255, 255, 0.568); */
  position: absolute;
  overflow: hidden;
}

.wrapper:before {
  position: absolute;
  content: "";
  background: inherit;
  height: 500px;
  width: 770px;
  left: 100px;
  right: -50px;
  top: 0px;
  bottom: 0;
  box-shadow: inset 0 0 0 3000px rgba(255, 255, 255, 0.682);
  filter: blur(10px);
  border-radius: 15px;
}

.text-gradient {
  background: -webkit-linear-gradient(#eee, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
