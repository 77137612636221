@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.io {
  color: #fef077;
  color: #702c6d;
}

:where(.css-dev-only-do-not-override-17seli4).ant-switch.ant-switch-checked {
  background: #702c6d !important;
}

@media (max-width: 1023px) {
  .auth-bg {
    /* background-color: green !important; */
    background: url("/src/assets/png/authbg.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}
